import { images } from "../images";

export const mediaSections = [
    {
      id: "media",
      title: "Media & Entertainment",
      content:
        "SanRaj Group is redefining media & entertainment by creating, distributing, and managing impactful content across film, television, digital, and print platforms. With a focus on quality and innovation, we deliver compelling stories that resonate globally, bringing together top-tier production, state-of-the-art facilities, and diverse creative talent. Committed to excellence, SanRaj Group is elevating the media landscape and enriching the entertainment experience for audiences worldwide. SanRaj Group specializes in end-to-end event management, offering services like luxury events, corporate gatherings, brand launches, and weddings. From online promotions to venue décor and celebrity management, we deliver seamless, memorable experiences.",
    },
    {
      id: "motion",
      title: "SanRaj Motion Pictures LLP",
      isTeam: true,
      teamData: [
        {
          id: 1,
          name: "SanRaj Motion Pictures LLP",
          logo: images.sportsMedia01.image,
          description: `SanRaj Motion Pictures LLP is set to become a prominent player in the entertainment industry, focusing on the production, broadcasting, and distribution of a diverse range of media content across television, film, digital, and print platforms. The company aims to reach audiences with captivating, high-quality content that spans across various genres and languages, tailored to both domestic and international audience.
          <br/>
          Beyond content creation, SanRaj Motion Pictures will manage state-of-the-art theatres and production facilities, ensuring end-to-end control over the production pipeline. The company will also provide specialized services in photography, videography, and other multimedia production, aligning with global standards. By branching into allied activities, SanRaj Motion Pictures will support diverse creative and technical projects, solidifying its role as a comprehensive hub for storytelling, entertainment, and innovative media solutions.
          `,
        },
      ],
    },
  ];